import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ArticleLink from '../components/article-link';

const ARABIC_SEASON = 'palestine-ways-of-being-arabic';

const PostList = ({
  data: { allWpSeason: { edges } },
}: any) => (
  <Layout categories="Season" style={{ backgroundImage: 'linear-gradient(to bottom, #ccc, #fff)' }}>
    <SEO />
    <div className="post-list post-list--seasons">
      {edges.filter(({ node } : any) => node.slug !== ARABIC_SEASON).map(({
        node,
      }: any) => (
        <ArticleLink
          title={node.title}
          imageData={node.featuredImage?.node?.localFile}
          slug={node.slug}
          date={`Season ${node.date}`}
          key={node.slug}
          group="seasons"
        />
      ))}
    </div>
  </Layout>
);

export default PostList;

export const postQuery = graphql`
query Seasons {
  allWpSeason {
    edges {
      node {
        title
        date(formatString: "MMMM YYYY")
        slug
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1024
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                  aspectRatio: 1.7375
                )
              }
            }
          }
        }
      }
    }
  }
}
`;
